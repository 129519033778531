import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Genesis`}</h1>
    <p>{`In the beginning, there was just a will to learn new things. My story is very common to other people who decide to learn how to code. I've always loved technology and always liked computers.`}</p>
    <p>{`When I was little I had a dream to be a game maker and create games. Discovering the software RPG game maker was a breakthrough and I spent hours just creating levels and stories that have never seen the light of the day.`}</p>
    <p>{`In year six I had a pretty bad maths teacher and always thought that I was too dumb to learn math and wouldn't be able to follow my dream to work with games. After that, I have always felt a bit lost and unsure of what to do in the future.`}</p>
    <h2>{`MOOCs, a girl and code`}</h2>
    <p>{`Fast forwarding a few years later. I was in love with this girl that was about to start a bachelor degree in computer science, we used to talk all the time and the idea of learning how to code came back to my mind.`}</p>
    <p>{`After a quick online search, I have picked up a book on Java and decided to start learning.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class java {
  public static void main(String[] args) {
     System.out.println("Hello World");
  }
}
`}</code></pre>
    <p>{`The first thing I did with Java didn't break tradition, this little `}<em parentName="p">{`hello world`}</em>{` was the most exciting thing that had happened that week. I kept trying to learn Java but the book jumped into the hard stuff and I started my second year in university - taking a Tourism degree - so I had to put on hold this goal of learning how to code.`}</p>
    <p>{`Later on, I had found `}<a parentName="p" {...{
        "href": "https://codecademy.com"
      }}>{`codecademy`}</a>{` and after `}<a parentName="p" {...{
        "href": "https://www.edx.org"
      }}>{`edx`}</a>{`, coding was again on my sights! Edx and the course from MIT Introduction to computer science using python really made me fall in love with code again.`}</p>
    <p>{`We had a study group and we used to talk all the time when we were stuck. I remember working on problems throughout the whole night and not even noticing that it was early in the morning already.`}</p>
    <p>{`At this time I was both working full time and finishing the last year of university at night so I couldn't complete the course in time. Still, I loved every hour spent trying to crack a problem and this course really helped me develop my problem-solving skills and increase this passion for code.`}</p>
    <h2>{`The future`}</h2>
    <p>{`I had one more attempt at sticking with learning how to code and creating stuff with it. But I have made the decision to move to the UK, between working as a pot wash for nearly 70 hours a week, living in a room with no internet and trying to start a new life in a different country, I had no time to keep up with studying and learning, but that is a story for another time...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      